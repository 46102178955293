import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

// ----------------------------------------------------------------------

const SupportPage = lazy(() => import('src/pages/dashboard/support'));

const MKTLoginPage = lazy(() => import('src/pages/dashboard/mkt-login'));

const ProfilePage = lazy(() => import('src/pages/account/account'));

// Proxy1
const Proxy1Page = lazy(() =>
  import('src/pages/dashboard/proxy/data-center-1/proxy-data-center-1')
);
const BuyProxy1Page = lazy(() => import('src/pages/dashboard/proxy/data-center-1/buy'));

// Proxy2
const Proxy2Page = lazy(() =>
  import('src/pages/dashboard/proxy/data-center-2/proxy-data-center-2')
);
const BuyProxy2Page = lazy(() => import('src/pages/dashboard/proxy/data-center-2/buy'));

// Proxy Residential
const ProxyResidentialPage = lazy(() =>
  import('src/pages/dashboard/proxy/population/population-proxy')
);
const BuyProxyPopulationPage = lazy(() => import('src/pages/dashboard/proxy/population/buy'));

// ProxyToken
const ProxyTokenPage = lazy(() => import('src/pages/dashboard/proxy/proxy-token/proxy-token'));
const BuyProxyTokenPage = lazy(() => import('src/pages/dashboard/proxy/proxy-token/buy'));

// VPS
const BuyVPSPage = lazy(() => import('src/pages/dashboard/vps/vps-buy'));
const VPSOrderPage = lazy(() => import('src/pages/dashboard/vps/vps-order'));

// transaction-history
const MyOrdersPage = lazy(() => import('src/pages/dashboard/transaction-history/my-orders'));
const MyRechargeHistoryPage = lazy(() =>
  import('src/pages/dashboard/transaction-history/my-recharge-history')
);

// Recharge
const RechargePage = lazy(() => import('src/pages/dashboard/recharge'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.support, element: <SupportPage /> },

      { path: paths.mktLogin, element: <MKTLoginPage /> },

      { path: paths.profile, element: <ProfilePage /> },

      // Proxy 1
      { path: paths.proxy.dataCenter1.purchased, element: <Proxy1Page /> },
      { path: paths.proxy.dataCenter1.buy, element: <BuyProxy1Page /> },

      // Proxy 2
      { path: paths.proxy.dataCenter2.purchased, element: <Proxy2Page /> },
      { path: paths.proxy.dataCenter2.buy, element: <BuyProxy2Page /> },

      // Population
      { path: paths.proxy.residential.purchased, element: <ProxyResidentialPage /> },
      { path: paths.proxy.residential.buy, element: <BuyProxyPopulationPage /> },

      // Proxy Token
      { path: paths.proxy.proxyToken.purchased, element: <ProxyTokenPage /> },
      { path: paths.proxy.proxyToken.buy, element: <BuyProxyTokenPage /> },

      // VPS
      { path: paths.vps.root, element: <Navigate to={paths.vps.buy} replace /> },
      { path: paths.vps.buy, element: <BuyVPSPage /> },
      { path: paths.vps.order, element: <VPSOrderPage /> },

      // transaction history
      { path: paths.order.orders, element: <MyOrdersPage /> },
      { path: paths.order.rechargeHistory, element: <MyRechargeHistoryPage /> },

      { path: paths.recharge, element: <RechargePage /> },
    ],
  },
];
