import axiosInstance, { endpoints } from 'src/utils/axios';

export const loginApi = (payload) => axiosInstance.post(endpoints.auth.login, payload);

export const loginWithGoogleApi = (payload) =>
  axiosInstance.post(endpoints.auth.loginWithGoogle, payload);

export const loginWithMKTLoginApi = (payload) =>
  axiosInstance.post(endpoints.auth.loginWithMKTLogin, payload);

export const registerApi = (payload) => axiosInstance.post(endpoints.auth.register, payload);

export const verifyEmailApi = (payload) => axiosInstance.post(endpoints.auth.verifyEmail, payload);

export const forgotPasswordApi = (payload) =>
  axiosInstance.post(endpoints.auth.forgotPassword, payload);

export const resetPasswordApi = (payload) =>
  axiosInstance.post(endpoints.auth.resetPassword, payload);

export const logoutApi = () => axiosInstance.post(endpoints.auth.logout);

export const changePasswordApi = (payload) =>
  axiosInstance.post(endpoints.auth.changePassword, payload);
