import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const SubscribeVietPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/subscribe/subscribe-viet')
);
const SubscribeNgoaiPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/subscribe/subscribe-ngoai')
);

// like video
const LikeVideoPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/like-video')
);

// dislike video
const DislikeVideoPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/dislike-video')
);

// view
const ViewNormalPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/view/view-normal')
);
const ViewSocialPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/view/view-social')
);
const ViewNativeADS30Page = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/view/view-native-ADS-30')
);
const ViewNativeADS500Page = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/view/view-native-ADS-500')
);

// watch time
const WatchTimePage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/watch-time')
);

// comment
const LikeCommentPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/comment/like-comment')
);
const CommentVietPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/comment/comment-viet')
);
const CommentNgoaiPage = lazy(() =>
  import('src/pages/dashboard/services/youtube-services/comment/comment-ngoai')
);

// ----------------------------------------------------------------------

export const youtubeRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.youtube.root,
        element: <Navigate to={paths.youtube.subscribeViet} replace />,
      },

      // youtube - like post
      {
        path: paths.youtube.subscribe,
        element: <Navigate to={paths.youtube.subscribeViet} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.youtube.subscribeViet, element: <SubscribeVietPage /> },
          { path: paths.youtube.subscribeNgoai, element: <SubscribeNgoaiPage /> },
        ],
      },

      // like video
      { path: paths.youtube.likeVideo, element: <LikeVideoPage /> },

      // dislike video
      { path: paths.youtube.dislikeVideo, element: <DislikeVideoPage /> },

      // watch time
      { path: paths.youtube.watchTime, element: <WatchTimePage /> },

      // like comment
      { path: paths.youtube.likeComment, element: <LikeCommentPage /> },

      // view
      {
        path: paths.youtube.view,
        element: <Navigate to={paths.youtube.viewNormal} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.youtube.viewNormal, element: <ViewNormalPage /> },
          { path: paths.youtube.viewSocial, element: <ViewSocialPage /> },
          { path: paths.youtube.viewNativeADS30, element: <ViewNativeADS30Page /> },
          { path: paths.youtube.viewNativeADS500, element: <ViewNativeADS500Page /> },
        ],
      },

      // comment
      {
        path: paths.youtube.comment,
        element: <Navigate to={paths.youtube.commentViet} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.youtube.commentViet, element: <CommentVietPage /> },
          { path: paths.youtube.commentNgoai, element: <CommentNgoaiPage /> },
        ],
      },
    ],
  },
];
