import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

const FollowShopeePage = lazy(() => import('src/pages/dashboard/services/shopee-services/follow'));

const LikeShopeePage = lazy(() => import('src/pages/dashboard/services/shopee-services/like'));

export const shopeeRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.shopee.root, element: <Navigate to={paths.shopee.follow} replace /> },
      { path: paths.shopee.follow, element: <FollowShopeePage /> },
      { path: paths.shopee.like, element: <LikeShopeePage /> },
    ],
  },
];
