import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// facebook - like post
const LikePost1FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-post/like-post-service-1')
);
const LikePost2FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-post/like-post-service-2')
);
const LikePost3FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-post/like-post-service-3')
);

// facebook - like page
const LikePage1FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-page/like-page-service-1')
);
const LikePage2FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-page/like-page-service-2')
);
const LikePage3FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-page/like-page-service-3')
);
const LikePage4FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/like-page/like-page-service-4')
);

// facebook - review page
const ReviewPageFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/review-page-service')
);

// facebook - check in page
const CheckInPageFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/check-in-page-service')
);

// facebook - check in page
const FriendFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/friend-service')
);

// facebook - follow
const Follow1FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/follow/follow-service-1')
);
const Follow2FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/follow/follow-service-2')
);
const Follow3FacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/follow/follow-service-3')
);

// facebook - comment
const CommentFacebook1Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/comment/comment-service-1')
);
const CommentFacebook2Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/comment/comment-service-2')
);
const CommentFacebook3Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/comment/comment-service-3')
);
const LikeCommentFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/comment/like-comment-service')
);

// facebook - live stream
const LiveStreamFacebook1Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/livestream/livestream-service-1')
);
const LiveStreamFacebook2Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/livestream/livestream-service-2')
);
const LiveStreamFacebook3Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/livestream/livestream-service-3')
);

// facebook -vip like
const VipLikeFacebook1Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/vip-like/vip-like-service-1')
);
const VipLikeFacebook2Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/vip-like/vip-like-service-2')
);
const VipLikeGroupFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/vip-like-group-service')
);

// facebook -vip comment
const VipCommentFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/vip-comment-service')
);

// facebook -vip view
const VipLiveFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/vip-live-stream-service')
);

// facebook - share group
const SharePostGroupFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/share-post-group-service')
);

// facebook - share profile
const SharePostProfileFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/share-post-profile-service')
);

// facebook -  member group
const MemberGroupFacebook1Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/member-group/member-group-service-1')
);
const MemberGroupFacebook2Page = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/member-group/member-group-service-2')
);

// facebook -  view
const VipViewFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/view/view-vip-service')
);

const NormalViewFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/view/view-normal-service')
);

const View600kFacebookPage = lazy(() =>
  import('src/pages/dashboard/services/facebook-services/view/view-600k-service')
);

// ----------------------------------------------------------------------

export const facebookRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.facebook.root,
        element: <Navigate to={paths.facebook.likePost1} replace />,
      },

      // facebook - like post
      {
        path: paths.facebook.likePost,
        element: <Navigate to={paths.facebook.likePost1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.likePost1, element: <LikePost1FacebookPage /> },
          { path: paths.facebook.likePost2, element: <LikePost2FacebookPage /> },
          { path: paths.facebook.likePost3, element: <LikePost3FacebookPage /> },
        ],
      },

      // facebook - like page
      {
        path: paths.facebook.likePage,
        element: <Navigate to={paths.facebook.likePage1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.likePage1, element: <LikePage1FacebookPage /> },
          { path: paths.facebook.likePage2, element: <LikePage2FacebookPage /> },
          { path: paths.facebook.likePage3, element: <LikePage3FacebookPage /> },
          { path: paths.facebook.likePage4, element: <LikePage4FacebookPage /> },
        ],
      },

      // facebook - review page
      { path: paths.facebook.reviewPage, element: <ReviewPageFacebookPage /> },

      // facebook - check in page
      { path: paths.facebook.checkInPage, element: <CheckInPageFacebookPage /> },

      // facebook - friend
      { path: paths.facebook.friend, element: <FriendFacebookPage /> },

      // facebook - follow
      {
        path: paths.facebook.follow,
        element: <Navigate to={paths.facebook.follow1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.follow1, element: <Follow1FacebookPage /> },
          { path: paths.facebook.follow2, element: <Follow2FacebookPage /> },
          { path: paths.facebook.follow3, element: <Follow3FacebookPage /> },
        ],
      },

      // facebook - comment
      {
        path: paths.facebook.comment,
        element: <Navigate to={paths.facebook.comment1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.comment1, element: <CommentFacebook1Page /> },
          { path: paths.facebook.comment2, element: <CommentFacebook2Page /> },
          { path: paths.facebook.comment3, element: <CommentFacebook3Page /> },
        ],
      },
      { path: paths.facebook.likeComment, element: <LikeCommentFacebookPage /> },

      // facebook - live stream
      {
        path: paths.facebook.liveStream,
        element: <Navigate to={paths.facebook.liveStream1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.liveStream1, element: <LiveStreamFacebook1Page /> },
          { path: paths.facebook.liveStream2, element: <LiveStreamFacebook2Page /> },
          { path: paths.facebook.liveStream3, element: <LiveStreamFacebook3Page /> },
        ],
      },

      // facebook - vip like
      {
        path: paths.facebook.vipLike,
        element: <Navigate to={paths.facebook.vipLike1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.vipLike1, element: <VipLikeFacebook1Page /> },
          { path: paths.facebook.vipLike2, element: <VipLikeFacebook2Page /> },
        ],
      },

      // facebook - vip like group
      { path: paths.facebook.vipLikeGroup, element: <VipLikeGroupFacebookPage /> },

      // facebook - vip comment
      { path: paths.facebook.vipComment, element: <VipCommentFacebookPage /> },

      // facebook - vip live stream
      { path: paths.facebook.vipLive, element: <VipLiveFacebookPage /> },

      // facebook - sharePostGroup
      { path: paths.facebook.sharePostGroup, element: <SharePostGroupFacebookPage /> },

      // facebook - sharePostWall
      { path: paths.facebook.sharePostWall, element: <SharePostProfileFacebookPage /> },

      // facebook - view
      {
        path: paths.facebook.view,
        element: <Navigate to={paths.facebook.vipView} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.vipView, element: <VipViewFacebookPage /> },
          { path: paths.facebook.normalView, element: <NormalViewFacebookPage /> },
          { path: paths.facebook.view600k, element: <View600kFacebookPage /> },
        ],
      },

      // facebook - member group
      {
        path: paths.facebook.memberGroup,
        element: <Navigate to={paths.facebook.memberGroup1} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.facebook.memberGroup1, element: <MemberGroupFacebook1Page /> },
          { path: paths.facebook.memberGroup2, element: <MemberGroupFacebook2Page /> },
        ],
      },
    ],
  },
];
