import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { getStorage, removeStorage, setStorage } from 'src/hooks/use-local-storage';
import { STORAGE_KEY } from 'src/utils/constants';
import { loginApi, loginWithGoogleApi, loginWithMKTLoginApi, registerApi } from 'src/api/auth.api';
import { enqueueSnackbar } from 'notistack';
import { AuthContext } from './auth-context';
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'UPDATE_BALANCE') {
    return {
      ...state,
      user: {
        ...state.user,
        balance: action.payload.balance,
      },
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getStorage(STORAGE_KEY.ACCESS_TOKEN);
      const refreshToken = getStorage(STORAGE_KEY.REFRESH_TOKEN);

      if (accessToken || refreshToken) {
        const response = await axios.get(endpoints.auth.me);

        const user = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
              refreshToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username, password) => {
    const data = {
      username,
      password,
    };

    const responseLogin = await loginApi(data);

    setStorage(STORAGE_KEY.ACCESS_TOKEN, responseLogin?.data?.access_token);
    setStorage(STORAGE_KEY.REFRESH_TOKEN, responseLogin?.data?.refresh_token);
    setStorage(STORAGE_KEY.DEVICE, responseLogin?.data?.device);

    const response = await axios.get(endpoints.auth.me);

    const user = response.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken: responseLogin?.data?.access_token,
          refreshToken: responseLogin?.data?.refresh_token,
        },
      },
    });
  }, []);

  // LOGIN WITH GOOGLE
  const loginWithGoogle = useCallback(async (token) => {
    const payload = {
      access_token: token,
    };
    const responseLogin = await loginWithGoogleApi(payload);
    const { access_token, refresh_token } = responseLogin.data;

    setStorage(STORAGE_KEY.ACCESS_TOKEN, access_token);
    setStorage(STORAGE_KEY.REFRESH_TOKEN, refresh_token);

    const response = await axios.get(endpoints.auth.me);

    const user = response.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken: access_token,
          refreshToken: refresh_token,
        },
      },
    });
  }, []);

  // LOGIN WITH MKT Login
  const loginWithMKTLogin = useCallback(async (token) => {
    const payload = {
      access_token: token,
    };
    const responseLogin = await loginWithMKTLoginApi(payload);
    const { access_token, refresh_token } = responseLogin.data;

    setStorage(STORAGE_KEY.ACCESS_TOKEN, access_token);
    setStorage(STORAGE_KEY.REFRESH_TOKEN, refresh_token);

    const response = await axios.get(endpoints.auth.me);

    const user = response.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken: access_token,
          refreshToken: refresh_token,
        },
      },
    });
  }, []);

  const updateBalance = useCallback(async (username, password) => {
    const response = await axios.get(endpoints.auth.me);

    const user = response.data;

    dispatch({
      type: 'UPDATE_BALANCE',
      payload: {
        balance: user.balance,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (username, email, password) => {
    const data = {
      username,
      email,
      password,
    };

    await registerApi(data);

    // const { message, email_verified_at } = response.data;

    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user: {
    //       ...user,
    //       accessToken,
    //     },
    //   },
    // });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      await axios.post(endpoints.auth.logout, {
        device: getStorage(STORAGE_KEY.DEVICE),
      });
      removeStorage(STORAGE_KEY.ACCESS_TOKEN_MKT_LOGIN);
      removeStorage(STORAGE_KEY.ACCESS_TOKEN);
      removeStorage(STORAGE_KEY.REFRESH_TOKEN);
      removeStorage(STORAGE_KEY.DEVICE);
    } catch (error) {
      enqueueSnackbar('Đã xảy ra lỗi. Vui lòng thử lại sau!', { variant: 'error' });
    }
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
      loginWithGoogle,
      loginWithMKTLogin,
      updateBalance,
    }),
    [state.user, status, login, register, logout, loginWithGoogle, loginWithMKTLogin, updateBalance]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
