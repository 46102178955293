import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

const MemberVietPage = lazy(() =>
  import('src/pages/dashboard/services/telegram-services/member-viet')
);

const MemberNgoaiPage = lazy(() =>
  import('src/pages/dashboard/services/telegram-services/member-ngoai')
);

export const telegramRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.telegram.root, element: <Navigate to={paths.telegram.memberViet} replace /> },
      { path: paths.telegram.member, element: <Navigate to={paths.telegram.memberViet} replace /> },
      { path: paths.telegram.memberViet, element: <MemberVietPage /> },
      { path: paths.telegram.memberNgoai, element: <MemberNgoaiPage /> },
    ],
  },
];
