import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const FollowInstagramPage = lazy(() =>
  import('src/pages/dashboard/cms/services/instagram-services/follow')
);
const LikeInstagramPage = lazy(() =>
  import('src/pages/dashboard/cms/services/instagram-services/like')
);
const ViewInstagramPage = lazy(() =>
  import('src/pages/dashboard/cms/services/instagram-services/view')
);
// ----------------------------------------------------------------------

export const cmsInstagramRoutes = [
  {
    path: paths.cms.service.instagram.root,
    element: <Navigate to={paths.cms.service.instagram.follow} replace />,
  },

  // like video
  { path: paths.cms.service.instagram.follow, element: <FollowInstagramPage /> },

  // watch time
  { path: paths.cms.service.instagram.like, element: <LikeInstagramPage /> },

  // like comment
  { path: paths.cms.service.instagram.view, element: <ViewInstagramPage /> },
];
