import numeral from 'numeral';

export function formatCurrencyVND(value, isUnit = true) {
  if (value === undefined || value === null) {
    return '';
  }
  const numericValue = Number(value);
  if (Number.isNaN(numericValue)) {
    return '';
  }

  const formattedNumber = numericValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  if (!isUnit) {
    return formattedNumber;
  }

  return `${formattedNumber} Credit`;
}

export function fCurrencyVND(number) {
  const format = number ? numeral(number).format('0,0') : '';

  return `${format || 0}₫`;
}
