import axiosInstance, { endpoints } from 'src/utils/axios';

export const getUsersCMSApi = (params) => axiosInstance.get(endpoints.cms.user.list, { params });

export const updateBalanceUserCMSApi = (id, payload) =>
  axiosInstance.put(endpoints.cms.user.updateBalance(id), payload);

export const updateRoleUserCMSApi = (id, payload) =>
  axiosInstance.put(endpoints.cms.user.updateRole(id), payload);

export const deleteUserByIdCMSApi = (id) => axiosInstance.delete(endpoints.cms.user.deleteById(id));

export const getQRCloudMiniApi = () => axiosInstance.get(endpoints.cms.recharge.cloudMiniQRCode);

export const getQRImetaApi = () => axiosInstance.get(endpoints.cms.recharge.imetaQRCode);
