import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// follow
const FollowVietPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/follow/follow-viet')
);
const FollowNhanhPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/follow/follow-nhanh')
);
const FollowNgoaiPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/follow/follow-ngoai')
);

// like
const LikePostVietPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/like/like-viet')
);
const LikePostNgoaiPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/like/like-ngoai')
);

// like favorite
const LikeFavoritePage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/like-favorite')
);

const ViewVideoPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/view-video')
);

const ShareVideoPage = lazy(() => import('src/pages/dashboard/cms/services/tiktok-services/share'));

const CommentPage = lazy(() => import('src/pages/dashboard/cms/services/tiktok-services/comment'));

// live stream
const LikeLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/livestream/like-livestream')
);
const ShareLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/livestream/share-livestream')
);
const PKLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/livestream/pk-livestream')
);
const ViewLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/cms/services/tiktok-services/livestream/view-livestream')
);

export const cmsTikTokRoutes = [
  {
    path: paths.cms.service.tiktok.root,
    element: <Navigate to={paths.cms.service.tiktok.followViet} replace />,
  },

  // tiktok - follow
  {
    path: paths.cms.service.tiktok.follow,
    element: <Navigate to={paths.cms.service.tiktok.followViet} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.tiktok.followViet, element: <FollowVietPage /> },
      { path: paths.cms.service.tiktok.followNgoai, element: <FollowNgoaiPage /> },
      { path: paths.cms.service.tiktok.followNhanh, element: <FollowNhanhPage /> },
    ],
  },

  // tiktok - like
  {
    path: paths.cms.service.tiktok.likePost,
    element: <Navigate to={paths.cms.service.tiktok.likePostViet} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.tiktok.likePostViet, element: <LikePostVietPage /> },
      { path: paths.cms.service.tiktok.likePostNgoai, element: <LikePostNgoaiPage /> },
    ],
  },

  // like favorite
  { path: paths.cms.service.tiktok.likeFavorite, element: <LikeFavoritePage /> },

  // view
  { path: paths.cms.service.tiktok.view, element: <ViewVideoPage /> },

  // share
  { path: paths.cms.service.tiktok.share, element: <ShareVideoPage /> },

  // Comment
  { path: paths.cms.service.tiktok.comment, element: <CommentPage /> },

  // tiktok - live stream
  {
    path: paths.cms.service.tiktok.livestream,
    element: <Navigate to={paths.cms.service.tiktok.likeLivestream} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.tiktok.likeLivestream, element: <LikeLiveStreamPage /> },
      { path: paths.cms.service.tiktok.shareLivestream, element: <ShareLiveStreamPage /> },
      { path: paths.cms.service.tiktok.pkLivestream, element: <PKLiveStreamPage /> },
      { path: paths.cms.service.tiktok.viewLivestream, element: <ViewLiveStreamPage /> },
    ],
  },
];
