import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const OauthAuthenticate = lazy(() => import('src/pages/auth/oauth/oauth-authenticate'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset-password'));
const JwtVerifyEmailView = lazy(() => import('src/pages/auth/jwt/verify-email'));
// ----------------------------------------------------------------------

const authJwt = {
  path: '/',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <AuthClassicLayout>
          <Outlet />
        </AuthClassicLayout>
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <JwtLoginPage />,
    },
    {
      path: 'register',
      element: <JwtRegisterPage />,
    },
    {
      path: 'forgot-password',
      element: <JwtForgotPasswordPage />,
    },
    {
      path: 'reset-password',
      element: <JwtResetPasswordPage />,
    },
  ],
};

const verifyEmail = {
  path: '/verify-email',
  element: <JwtVerifyEmailView />,
};

const oauth = {
  path: '/login/oauth',
  element: <OauthAuthenticate />,
};

export const authRoutes = [authJwt, verifyEmail, oauth];
