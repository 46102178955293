import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import { paths } from '../../paths';

const LikePost1CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-post/like-post-service-1')
);
const LikePost2CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-post/like-post-service-2')
);

const LikePost3CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-post/like-post-service-3')
);

// like page
const LikePage1CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-page/like-page-service-1')
);
const LikePage2CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-page/like-page-service-2')
);
const LikePage3CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-page/like-page-service-3')
);
const LikePage4CMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/like-page/like-page-service-4')
);

// facebook - review page
const ReviewPageFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/review-page-service')
);

// facebook - check in page
const CheckInPageFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/check-in-page-service')
);

// facebook - check in page
const FriendFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/friend-service')
);

// facebook - follow
const Follow1FacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/follow/follow-service-1')
);
const Follow2FacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/follow/follow-service-2')
);
const Follow3FacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/follow/follow-service-3')
);
// facebook - comment
const CommentFacebook1Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/comment/comment-service-1')
);
const CommentFacebook2Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/comment/comment-service-2')
);
const CommentFacebook3Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/comment/comment-service-3')
);
const LikeCommentFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/comment/like-comment-service')
);

// facebook - live stream
const LiveStreamFacebook1Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/livestream/livestream-service-1')
);
const LiveStreamFacebook2Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/livestream/livestream-service-2')
);
const LiveStreamFacebook3Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/livestream/livestream-service-3')
);

// facebook -vip like
const VipLikeFacebook1Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/vip-like/vip-like-service-1')
);
const VipLikeFacebook2Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/vip-like/vip-like-service-2')
);
const VipLikeGroupFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/vip-like-group-service')
);

// facebook -vip comment
const VipCommentFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/vip-comment-service')
);

// facebook -vip view
const VipLiveFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/vip-live-stream-service')
);

// facebook - share group
const SharePostGroupFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/share-post-group-service')
);

// facebook - share profile
const SharePostProfileFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/share-post-profile-service')
);

// facebook -  member group
const MemberGroupFacebook1Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/member-group/member-group-service-1')
);
const MemberGroupFacebook2Page = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/member-group/member-group-service-2')
);

// facebook -  view
const VipViewFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/view/view-vip-service')
);

const NormalViewFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/view/view-normal-service')
);

const View600kFacebookPage = lazy(() =>
  import('src/pages/dashboard/cms/services/facebook-services/view/view-600k-service')
);

export const cmsFacebookServiceRoutes = [
  {
    path: paths.cms.service.facebook.root,
    element: <Navigate to={paths.cms.service.facebook.likePost1} replace />,
  },
  // facebook - like post
  {
    path: paths.cms.service.facebook.likePost,
    element: <Navigate to={paths.cms.service.facebook.likePost1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.likePost1, element: <LikePost1CMSPage /> },
      { path: paths.cms.service.facebook.likePost2, element: <LikePost2CMSPage /> },
      { path: paths.cms.service.facebook.likePost3, element: <LikePost3CMSPage /> },
    ],
  },

  // facebook - like page
  {
    path: paths.cms.service.facebook.likePage,
    element: <Navigate to={paths.cms.service.facebook.likePage1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.likePage1, element: <LikePage1CMSPage /> },
      { path: paths.cms.service.facebook.likePage2, element: <LikePage2CMSPage /> },
      { path: paths.cms.service.facebook.likePage3, element: <LikePage3CMSPage /> },
      { path: paths.cms.service.facebook.likePage4, element: <LikePage4CMSPage /> },
    ],
  },

  // facebook - review page
  { path: paths.cms.service.facebook.reviewPage, element: <ReviewPageFacebookPage /> },

  // facebook - check in page
  { path: paths.cms.service.facebook.checkInPage, element: <CheckInPageFacebookPage /> },

  // facebook - friend
  { path: paths.cms.service.facebook.friend, element: <FriendFacebookPage /> },

  // facebook - follow
  {
    path: paths.cms.service.facebook.follow,
    element: <Navigate to={paths.cms.service.facebook.follow1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.follow1, element: <Follow1FacebookPage /> },
      { path: paths.cms.service.facebook.follow2, element: <Follow2FacebookPage /> },
      { path: paths.cms.service.facebook.follow3, element: <Follow3FacebookPage /> },
    ],
  },
  // facebook - comment
  {
    path: paths.cms.service.facebook.comment,
    element: <Navigate to={paths.cms.service.facebook.comment1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.comment1, element: <CommentFacebook1Page /> },
      { path: paths.cms.service.facebook.comment2, element: <CommentFacebook2Page /> },
      { path: paths.cms.service.facebook.comment3, element: <CommentFacebook3Page /> },
    ],
  },
  { path: paths.cms.service.facebook.likeComment, element: <LikeCommentFacebookPage /> },

  // facebook - live stream
  {
    path: paths.cms.service.facebook.liveStream,
    element: <Navigate to={paths.cms.service.facebook.liveStream1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.liveStream1, element: <LiveStreamFacebook1Page /> },
      { path: paths.cms.service.facebook.liveStream2, element: <LiveStreamFacebook2Page /> },
      { path: paths.cms.service.facebook.liveStream3, element: <LiveStreamFacebook3Page /> },
    ],
  },

  // facebook - vip like
  {
    path: paths.cms.service.facebook.vipLike,
    element: <Navigate to={paths.cms.service.facebook.vipLike1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.vipLike1, element: <VipLikeFacebook1Page /> },
      { path: paths.cms.service.facebook.vipLike2, element: <VipLikeFacebook2Page /> },
    ],
  },

  // facebook - vip like group
  { path: paths.cms.service.facebook.vipLikeGroup, element: <VipLikeGroupFacebookPage /> },

  // facebook - vip comment
  { path: paths.cms.service.facebook.vipComment, element: <VipCommentFacebookPage /> },

  // facebook - vip live stream
  { path: paths.cms.service.facebook.vipLive, element: <VipLiveFacebookPage /> },

  // facebook - sharePostGroup
  { path: paths.cms.service.facebook.sharePostGroup, element: <SharePostGroupFacebookPage /> },

  // facebook - sharePostWall
  { path: paths.cms.service.facebook.sharePostWall, element: <SharePostProfileFacebookPage /> },

  // facebook - view
  {
    path: paths.cms.service.facebook.view,
    element: <Navigate to={paths.cms.service.facebook.vipView} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.vipView, element: <VipViewFacebookPage /> },
      { path: paths.cms.service.facebook.normalView, element: <NormalViewFacebookPage /> },
      { path: paths.cms.service.facebook.view600k, element: <View600kFacebookPage /> },
    ],
  },

  // facebook - member group
  {
    path: paths.cms.service.facebook.memberGroup,
    element: <Navigate to={paths.cms.service.facebook.memberGroup1} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.facebook.memberGroup1, element: <MemberGroupFacebook1Page /> },
      { path: paths.cms.service.facebook.memberGroup2, element: <MemberGroupFacebook2Page /> },
    ],
  },
];
