import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { fCurrencyVND, formatCurrencyVND } from 'src/utils/format-money';
import { ROLE } from 'src/utils/constants';
import { getQRCloudMiniApi, getQRImetaApi } from 'src/api/admin/users.api';
import { useMultiBoolean } from 'src/hooks/use-multiple-boolean';
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
  SettingsButton,
  // LanguagePopover,
  // ContactsPopover,
  // NotificationsPopover,
} from '../_common';
import RechargeBankingDialog from './recharge-banking-dialog';

// ----------------------------------------------------------------------

const defBtnText = {
  cloudMini: 'Cloud Mini',
  imeta: 'Imeta',
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { user } = useAuthContext();
  const smUp = useResponsive('up', 'sm');
  const open = useMultiBoolean({
    cloudMini: false,
    imeta: false,
  });
  const [titleBtn, setTitleBtn] = useState(defBtnText);
  const [fade, setFade] = useState({
    cloudMini: false,
    imeta: false,
  });
  const [qr, setQr] = useState({
    cloudMini: '',
    imeta: '',
  });
  const [balance, setBalance] = useState({
    cloudMini: 0,
    imeta: 0,
  });

  const handleMouseEnter = (name) => {
    setFade((prev) => ({ ...prev, [name]: true }));
    setTimeout(() => {
      setTitleBtn((prev) => ({ ...prev, [name]: 'Nạp tiền' }));
      setFade((prev) => ({ ...prev, [name]: false }));
    }, 100);
  };

  const handleMouseLeave = (name) => {
    setFade((prev) => ({ ...prev, [name]: true }));
    setTimeout(() => {
      setTitleBtn((prev) => ({ ...prev, [name]: defBtnText[name] }));
      setFade((prev) => ({ ...prev, [name]: false }));
    }, 100);
  };

  const [currentCheckCMSPath, setCurrentCheckCMSPath] = useState(false);

  useEffect(() => {
    const { pathname } = window.location;
    // Kiểm tra nếu pathname không phải là '/profile'
    if (pathname !== '/profile') {
      const isCMSPath = pathname.split('/')[1] === 'cms';
      setCurrentCheckCMSPath(isCMSPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    const fetchQR = async () => {
      try {
        const [cloudMiniResponse, imetaResponse] = await Promise.all([
          getQRCloudMiniApi(),
          getQRImetaApi(),
        ]);

        if (cloudMiniResponse.data) {
          setQr((prev) => ({
            ...prev,
            cloudMini: cloudMiniResponse.data.qrDataURL,
          }));
          setBalance((prev) => ({
            ...prev,
            cloudMini: cloudMiniResponse.data.balance,
          }));
        }
        if (imetaResponse.data) {
          setQr((prev) => ({
            ...prev,
            imeta: imetaResponse.data.qrDataURL,
          }));
          setBalance((prev) => ({
            ...prev,
            imeta: imetaResponse.data.balance,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (user?.role === ROLE.ADMIN || user?.role === ROLE.SUPER_ADMIN) {
      fetchQR();
    }
  }, [user?.role]);

  const determineButton = () => {
    if (user?.role === ROLE.ADMIN || user?.role === ROLE.SUPER_ADMIN) {
      const path = currentCheckCMSPath ? paths.proxy.dataCenter1.purchased : paths.cms.proxy;
      const label = currentCheckCMSPath ? 'Sản Phẩm' : 'Quản Lý';

      return smUp ? (
        <Button
          onClick={() => {
            window.open(path, '_blank', 'noopener,noreferrer');
          }}
          variant="outlined"
          startIcon={
            <Iconify icon={currentCheckCMSPath ? 'ep:goods' : 'carbon:cloud-service-management'} />
          }
          color="primary"
        >
          {label}
        </Button>
      ) : (
        <IconButton
          onClick={() => {
            window.open(path, '_blank', 'noopener,noreferrer');
          }}
          color="primary"
        >
          <Iconify icon={currentCheckCMSPath ? 'ep:goods' : 'carbon:cloud-service-management'} />
        </IconButton>
      );
    }

    if (user?.role === ROLE.CUSTOMER) {
      return smUp ? (
        <Button onClick={() => router.push(paths.recharge)} color="primary" variant="outlined">
          Nạp tiền
        </Button>
      ) : (
        <IconButton onClick={() => router.push(paths.recharge)} color="primary">
          <Iconify icon="fluent-emoji-high-contrast:money-bag" />
        </IconButton>
      );
    }

    return null;
  };

  const settings = useSettingsContext();

  const router = useRouter();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        {(user?.role === ROLE.ADMIN || user?.role === ROLE.SUPER_ADMIN) && (
          <>
            <Stack
              alignItems="center"
              mx={2}
              onMouseEnter={() => handleMouseEnter('imeta')}
              onMouseLeave={() => handleMouseLeave('imeta')}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => open.onTrue('imeta')}
            >
              <Typography>{fCurrencyVND(balance?.imeta ?? 0)}</Typography>
              <Button
                size="small"
                color="primary"
                sx={{
                  py: 0,
                  px: 1,
                  width: 'fit-content',
                  height: 'fit-content',
                  transition: 'opacity 0.1s ease',
                  opacity: fade.imeta ? 0.4 : 1,
                  minWidth: 82,
                }}
              >
                {titleBtn?.imeta}
              </Button>
            </Stack>
            <Stack
              alignItems="center"
              mx={2}
              onMouseEnter={() => handleMouseEnter('cloudMini')}
              onMouseLeave={() => handleMouseLeave('cloudMini')}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => open.onTrue('cloudMini')}
            >
              <Typography>{fCurrencyVND(balance?.cloudMini ?? 0)}</Typography>
              <Button
                size="small"
                color="primary"
                sx={{
                  py: 0,
                  px: 1,
                  width: 'fit-content',
                  height: 'fit-content',
                  transition: 'opacity 0.1s ease',
                  opacity: fade.cloudMini ? 0.4 : 1,
                  minWidth: 82,
                }}
              >
                {titleBtn?.cloudMini}
              </Button>
            </Stack>
          </>
        )}

        <Label
          variant="soft"
          color="primary"
          sx={{ fontSize: { xs: '12px', md: '18px' }, p: 2, mr: 1 }}
        >
          {formatCurrencyVND(user?.balance)}
        </Label>

        {determineButton()}

        <SettingsButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
        <RechargeBankingDialog
          title="Nạp tiền vào Cloud Mini"
          open={open.value.cloudMini}
          onClose={() => open.onFalse('cloudMini')}
          qr={qr.cloudMini}
        />
        <RechargeBankingDialog
          title="Nạp tiền vào Imeta"
          open={open.value.imeta}
          onClose={() => open.onFalse('imeta')}
          qr={qr.imeta}
        />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
