import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const FollowTwitterPage = lazy(() =>
  import('src/pages/dashboard/cms/services/twitter-services/follow')
);
const LikeTwitterPage = lazy(() =>
  import('src/pages/dashboard/cms/services/twitter-services/like')
);

// ----------------------------------------------------------------------

export const cmsTwitterRoutes = [
  {
    path: paths.cms.service.twitter.root,
    element: <Navigate to={paths.cms.service.twitter.follow} replace />,
  },

  { path: paths.cms.service.twitter.follow, element: <FollowTwitterPage /> },

  { path: paths.cms.service.twitter.like, element: <LikeTwitterPage /> },
];
