import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const ReviewGoogleMapPage = lazy(() =>
  import('src/pages/dashboard/cms/services/google-services/review-map')
);

// ----------------------------------------------------------------------

export const cmsGoogleRoutes = [
  {
    path: paths.cms.service.google.root,
    element: <Navigate to={paths.cms.service.google.reviewMap} replace />,
  },

  { path: paths.cms.service.google.reviewMap, element: <ReviewGoogleMapPage /> },
];
