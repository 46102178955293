import { Helmet } from 'react-helmet-async';
// sections
import Page500 from 'src/sections/error/500-view';

// ----------------------------------------------------------------------

export default function ServerErrorPage() {
  return (
    <>
      <Helmet>
        <title> Server đang bảo trì</title>
      </Helmet>

      <Page500 />
    </>
  );
}
