const STORAGE_KEY = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  DEVICE: 'device',
  ACCESS_TOKEN_MKT_LOGIN: 'MKTLoginToken',
  COLUMN_SETTING: 'columnSetting',
};

const ROLE = {
  CUSTOMER: 'customer',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
};

const ERROR_CODES = {
  INSUFFICIENT_BALANCE: 10001,
  INSUFFICIENT_PROXIES: 10002,
  TRANSFER_YOURSELF: 10003,
  SERVER_MAINTAIN: 10004,
  EXISTED_USER: 10005,
};

const VERSIONS = [
  { name: 'Chọn IP version', value: 'version' },
  { name: 'IPv4', value: 'v4' },
  { name: 'IPv6', value: 'v6' },
];

const DATA_CENTER = {
  DATA_CENTER_1: 'data-center-1',
  DATA_CENTER_2: 'data-center-2',
  PROXY_RES: 'residential',
  PROXY_TOKEN: 'proxy-token',
};

const DURATIONS = [
  { name: '14 ngày', value: 14 },
  { name: '30 ngày', value: 30 },
  { name: '60 ngày', value: 60 },
  { name: '90 ngày', value: 90 },
  { name: '180 ngày', value: 180 },
  { name: '270 ngày', value: 270 },
  { name: '365 ngày', value: 365 },
];

const TRANSACTION_TYPE = {
  TOP_UP_AUTO: 'top_up_auto',
  TOP_UP_MANUAL: 'top_up_manual',
  DEDUCT_MANUAL: 'deduct_manual',
  REFUND_SERVICE: 'refund_service',
};

const TRANSACTION_TYPE_ARR = [
  { name: 'Nạp tiền tự động', value: TRANSACTION_TYPE.TOP_UP_AUTO },
  { name: 'Nạp tiền thủ công', value: TRANSACTION_TYPE.TOP_UP_MANUAL },
  { name: 'Trừ tiền thủ công', value: TRANSACTION_TYPE.DEDUCT_MANUAL },
  { name: 'Hoàn tiền dịch vụ', value: TRANSACTION_TYPE.REFUND_SERVICE },
];

const BANKING_TYPE = [
  { name: 'Gửi đi', value: 'send-out' },
  { name: 'Nhận', value: 'receive' },
];

const OPTIONS_FETCH = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
};

const VENDOR = {
  MKT_CITY: 'mkt_city',
  HKNET: 'hknet',
  CLOUD_MINI: 'cloud_mini',
};

const VENDORS_ARR = [
  { name: 'MKT City', value: 'mkt_city' },
  // { name: 'HKNet', value: 'hknet' },
  { name: 'Cloud Mini', value: 'cloud_mini' },
  { name: 'Bright Data', value: 'bright_data' },
];

const STATUS_VPS = [
  { name: 'Hoạt động', value: 'active' },
  { name: 'Thu hồi', value: 'revoke' },
];

const VENDOR_VPS = [
  { name: 'PQ', value: 'pq' },
  { name: 'BKNS', value: 'bkns' },
  { name: 'Vutrl', value: 'vutrl' },
  { name: 'Lanit', value: 'lanit' },
];

const ERROR_STATUS_CODE = [404, 400, 403];

const REACTION_TYPE = [
  { name: 'Thích', value: 'like' },
  { name: 'Thương thương', value: 'care' },
  { name: 'Yêu thích', value: 'love' },
  { name: 'Haha', value: 'haha' },
  { name: 'Wow', value: 'wow' },
  { name: 'Buồn', value: 'sad' },
  { name: 'Phẫn nộ', value: 'angry' },
];

const FACEBOOK_SERVICE_TYPE = {
  likePost1: 'facebook-like-post-1',
  likePost2: 'facebook-like-post-2',
  likePost3: 'facebook-like-post-3',

  comment1: 'facebook-comment-1',
  comment2: 'facebook-comment-2',
  comment3: 'facebook-comment-3',

  likeComment: 'facebook-like-comment',

  likePage1: 'facebook-like-page-1',
  likePage2: 'facebook-like-page-2',
  likePage3: 'facebook-like-page-3',
  likePage4: 'facebook-like-page-4',

  reviewPage: 'facebook-review-page',

  checkInPage: 'facebook-check-in-page',

  follow1: 'facebook-follow-1',
  follow2: 'facebook-follow-2',
  follow3: 'facebook-follow-3',

  friend: 'facebook-friend',

  livestream1: 'facebook-livestream-1',
  livestream2: 'facebook-livestream-2',
  livestream3: 'facebook-livestream-3',

  vipLike: 'facebook-vip-like',
  vipLike1: 'facebook-vip-like-1',
  vipLike2: 'facebook-vip-like-2',

  vipLikeGroup: 'facebook-vip-like-group',

  vipComment: 'facebook-vip-comment',

  vipLive: 'facebook-vip-live',

  sharePostGroup: 'facebook-share-post-group',

  sharePostProfile: 'facebook-share-post-profile',

  member1: 'facebook-member-1',
  member2: 'facebook-member-2',

  view600k: 'facebook-view-600k',

  vipView: 'facebook-view-vip',
  normalView: 'facebook-view-3s',
};

const PLATFORM_TYPE = [
  { name: 'Facebook', value: 'facebook' },
  { name: 'Youtube', value: 'youtube' },
  { name: 'Instagram', value: 'instagram' },
  { name: 'TikTok', value: 'tiktok' },
  { name: 'Shopee', value: 'shopee' },
  { name: 'Twitter', value: 'twitter' },
  { name: 'Telegram', value: 'telegram' },
  { name: 'Lazada', value: 'lazada' },
  { name: 'Google', value: 'google' },
];

const STATUS_ORDER_SERVICE = [
  { name: 'Đang thực hiện', color: 'warning', value: 'running' },
  { name: 'Hoàn thành', color: 'success', value: 'complete' },
  { name: 'Hoàn trả', color: 'error', value: 'refund' },
  { name: 'Đã hoàn trả', color: 'success', value: 'refunded' },
  { name: 'Bảo hành', color: 'info', value: 'maintenance' },
  { name: 'Lỗi ID', color: 'error', value: 'error-id' },
];

const ORDER_TYPE = [
  { name: 'Proxy', value: 'proxy' },
  { name: 'VPS', value: 'vps' },
  { name: 'Facebook', value: 'facebook' },
  { name: 'Youtube', value: 'youtube' },
  { name: 'Instagram', value: 'instagram' },
  { name: 'TikTok', value: 'tiktok' },
  { name: 'Shopee', value: 'shopee' },
  { name: 'Twitter', value: 'twitter' },
  { name: 'Telegram', value: 'telegram' },
  { name: 'Lazada', value: 'lazada' },
  { name: 'Google', value: 'google' },
];

const VPS_TYPE = [
  { value: 'vps1', name: 'VPS 1' },
  { value: 'vps2', name: 'VPS 2' },
  { value: 'vps-custom', name: 'VPS Custom' },
];

const REGIONS = [
  { value: 'Nevada', name: 'US - Nevada', country: 'US' },
  { value: 'California', name: 'US - California', country: 'US' },
  { value: 'Texas', name: 'US - Texas', country: 'US' },
  { value: 'New Jersey', name: 'US - New Jersey', country: 'US' },
  { value: 'Virginia', name: 'US - Virginia', country: 'US' },
  { value: 'New York', name: 'US - New York', country: 'US' },
  { value: 'Colorado', name: 'US - Colorado', country: 'US' },
  { value: 'Florida', name: 'US - Florida', country: 'US' },
  { value: 'Massachusetts', name: 'US - Massachusetts', country: 'US' },
  { value: 'Illinois', name: 'US - Illinois', country: 'US' },
  { value: 'Washington', name: 'US - Washington', country: 'US' },
  { value: 'Oregon', name: 'US - Oregon', country: 'US' },
  { value: 'North Carolina', name: 'US - North Carolina', country: 'US' },
  { value: 'Wisconsin', name: 'US - Wisconsin', country: 'US' },
  { value: 'Indiana', name: 'US - Indiana', country: 'US' },
  { value: 'Ohio', name: 'US - Ohio', country: 'US' },
  { value: 'New Mexico', name: 'US - New Mexico', country: 'US' },
  { value: 'Georgia', name: 'US - Georgia', country: 'US' },
  { value: 'Michigan', name: 'US - Michigan', country: 'US' },
  { value: 'Utah', name: 'US - Utah', country: 'US' },
  { value: 'Missouri', name: 'US - Missouri', country: 'US' },
  { value: 'Arizona', name: 'US - Arizona', country: 'US' },
  { value: 'United Kingdom', name: 'UK - United Kingdom', country: 'UK' },
  { value: 'Singapore', name: 'SG - Singapore', country: 'SG' },
  { value: 'Australia', name: 'AU - Úc', country: 'AU' },
  { value: 'Poland', name: 'PL - Ba Lan', country: 'PL' },
  { value: 'Germany', name: 'DE - Đức', country: 'DE' },
  { value: 'Italy', name: 'IT - Ý', country: 'IT' },
  { value: 'Belgium', name: 'BE - Bỉ', country: 'BE' },
  { value: 'Czech Republic', name: 'CZ - Cộng Hòa Séc', country: 'CZ' },
  { value: 'Finland', name: 'FI - Phần Lan', country: 'FI' },
  { value: 'Ireland', name: 'IE - Ireland', country: 'IE' },
  { value: 'Lithuania', name: 'LT - Lithuania', country: 'LT' },
  { value: 'Portugal', name: 'PT - Bồ Đào Nha', country: 'PT' },
  { value: 'Việt Nam - Hà Nội', name: 'VN - Hà Nội', country: 'VN' },
  { value: 'Việt Nam - FPT', name: 'VN - FPT', country: 'VN' },
  { value: 'Việt Nam - CMC', name: 'VN - CMC', country: 'VN' },
  { value: 'Việt Nam - Viettel', name: 'VN - Viettel', country: 'VN' },
  { value: 'Netherlands', name: 'NL - Netherlands', country: 'NL' },
  { value: 'Canada', name: 'CA - Canada', country: 'CA' },
  { value: 'France', name: 'FR - Pháp', country: 'FR' },
];

const OPERATING_SYSTEMS = [
  { value: 'win2012', name: 'Windows Server 2012' },
  { value: 'win2016', name: 'Windows Server 2016' },
  { value: 'win2019', name: 'Windows Server 2019' },
  { value: 'win2022', name: 'Windows Server 2022' },
  { value: 'win10', name: 'Windows 10' },
  { value: 'centos7', name: 'CentOS 7' },
  { value: 'centos8', name: 'CentOS 8' },
  { value: 'ubuntu', name: 'Ubuntu 20.04' },
  { value: 'ubuntu18', name: 'Ubuntu 18.04' },
  { value: 'ubuntu22', name: 'Ubuntu 22.04' },
  { value: 'almalinux8', name: 'AlmaLinux 8' },
];

const VENDORS_SERVICE_ARR = [{ name: 'HKNet', value: 'hknet' }];

const BANK_ACCOUNT = {
  accountName: 'Luong Ngoc Thong',
  template: 'cBsORRB',
  accountNo: '40931567',
  acqId: '970416',
  bankName: 'Ngân hàng thương mại cổ phần Á Châu',
};

const REGIONS_RESIDENTIAL = [
  { value: 'US', label: 'Hoa Kỳ' },
  { value: 'AT', label: 'Áo' },
  { value: 'GB', label: 'Vương Quốc Anh' },
  { value: 'SG', label: 'Singapore' },
  { value: 'PL', label: 'Ba Lan' },
  { value: 'NL', label: 'Hà Lan' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BE', label: 'Bỉ' },
  { value: 'DE', label: 'Đức' },
  { value: 'BR', label: 'Brazil' },
  { value: 'DK', label: 'Đan Mạch' },
  { value: 'CZ', label: 'Cộng hòa Séc' },
  { value: 'IT', label: 'Ý' },
  { value: 'CA', label: 'Canada' },
  { value: 'FR', label: 'Pháp' },
  { value: 'ES', label: 'Tây Ban Nha' },
  { value: 'HK', label: 'Hồng Kông' },
  { value: 'JP', label: 'Nhật Bản' },
  { value: 'HU', label: 'Hungary' },
  { value: 'AU', label: 'Úc' },
  { value: 'CH', label: 'Thụy Sĩ' },
  { value: 'NO', label: 'Na Uy' },
];

const PROXY_TYPE = {
  DATA_CENTER_1: 'budget',
  DATA_CENTER_2: 'private',
};

export {
  STORAGE_KEY,
  ROLE,
  ERROR_CODES,
  VERSIONS,
  DURATIONS,
  OPTIONS_FETCH,
  VENDOR,
  ERROR_STATUS_CODE,
  STATUS_VPS,
  VENDOR_VPS,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_ARR,
  VENDORS_ARR,
  REACTION_TYPE,
  FACEBOOK_SERVICE_TYPE,
  PLATFORM_TYPE,
  VENDORS_SERVICE_ARR,
  STATUS_ORDER_SERVICE,
  ORDER_TYPE,
  DATA_CENTER,
  REGIONS,
  OPERATING_SYSTEMS,
  VPS_TYPE,
  BANK_ACCOUNT,
  BANKING_TYPE,
  REGIONS_RESIDENTIAL,
  PROXY_TYPE,
};
