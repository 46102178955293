import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 128 128"
        enableBackground="new 0 0 128 128"
        xmlSpace="preserve"
      >
        <path
          fill="#2C75C7"
          opacity="1.000000"
          stroke="none"
          d="
M84.000000,129.000000 
	C56.000000,129.000000 28.500000,129.000000 1.000000,129.000000 
	C1.000000,86.333336 1.000000,43.666668 1.000000,1.000000 
	C43.666668,1.000000 86.333336,1.000000 129.000000,1.000000 
	C129.000000,43.666668 129.000000,86.333336 129.000000,129.000000 
	C114.166664,129.000000 99.333336,129.000000 84.000000,129.000000 
M32.390591,100.777374 
	C32.586407,100.482018 32.782223,100.186653 32.967247,99.965981 
	C32.967247,99.965981 32.892860,99.953232 33.653450,99.873665 
	C34.394547,97.302368 35.735691,94.740646 35.776852,92.158188 
	C36.000763,78.109253 35.904182,64.055206 36.606037,50.130020 
	C41.491550,54.668591 46.311848,59.280972 51.307262,63.695171 
	C52.597275,64.835083 54.437077,65.352814 56.735840,66.097298 
	C57.206909,66.030197 57.677975,65.963097 58.085968,65.981888 
	C58.085968,65.981888 58.000195,65.918640 58.301388,66.334541 
	C58.563828,66.552773 58.826267,66.771004 59.020706,66.855972 
	C59.020706,66.855972 59.153496,66.787056 59.005856,67.529114 
	C58.993259,68.032631 58.980663,68.536156 59.156227,69.614746 
	C59.771137,70.077492 60.386044,70.540230 61.748482,71.021019 
	C62.495586,71.044525 63.242691,71.068024 64.582771,71.491341 
	C67.604156,70.272331 71.139557,69.677299 73.537720,67.701744 
	C78.232803,63.834034 82.169014,59.059372 86.684708,54.951866 
	C88.258293,53.520523 90.614655,52.949730 92.795876,52.909702 
	C92.810844,65.741028 92.827026,78.572357 92.840363,91.403679 
	C92.849838,100.519676 92.848129,100.519676 102.311493,101.973869 
	C106.422310,101.678162 107.996696,99.352379 107.984947,95.369965 
	C107.925659,75.282928 108.010002,55.195236 107.863312,35.109093 
	C107.850883,33.408089 106.507774,31.716812 105.918930,29.969337 
	C105.918930,29.969337 105.970398,30.105492 105.744553,29.474365 
	C105.300484,28.977203 104.856415,28.480042 103.787209,27.785156 
	C97.953415,25.240330 95.345589,26.031034 92.377075,31.332151 
	C92.320129,31.428308 92.191559,31.563776 92.218948,31.613651 
	C92.323792,31.804680 92.478745,31.968210 92.797134,32.963554 
	C92.735153,34.641148 92.673164,36.318741 91.868729,38.116051 
	C91.217720,38.465096 90.566719,38.814137 89.257668,38.802799 
	C85.697357,35.734730 82.358803,36.228630 79.209862,39.448776 
	C74.480125,44.285469 69.761467,49.132999 64.393059,54.637302 
	C58.882744,48.829575 53.960217,43.470417 48.846210,38.300671 
	C43.990993,33.392532 40.338905,33.993156 36.291325,40.037315 
	C36.291325,40.037315 35.877941,40.030201 35.896656,39.142540 
	C35.156528,35.725800 37.756367,31.378559 32.691750,28.463673 
	C29.754391,28.044174 26.523167,26.720011 23.989426,27.543051 
	C22.459270,28.040094 21.228018,31.862246 21.201784,34.205334 
	C20.983629,53.689682 21.072741,73.177528 21.079687,92.664322 
	C21.082304,100.000664 22.365547,101.409424 30.131077,101.825729 
	C30.757397,101.513046 31.383717,101.200363 31.989809,100.995239 
	C31.989809,100.995239 31.882315,100.974609 32.390591,100.777374 
M91.519691,96.499138 
	C91.519691,96.499138 91.480934,96.515205 91.519691,96.499138 
z"
        />
        <path
          fill="#F8F9FB"
          opacity="1.000000"
          stroke="none"
          d="
M101.480003,101.940048 
	C92.848129,100.519676 92.849838,100.519676 92.840363,91.403679 
	C92.827026,78.572357 92.810844,65.741028 92.780991,52.044785 
	C92.714462,46.785358 92.662819,42.390846 92.611183,37.996334 
	C92.673164,36.318741 92.735153,34.641148 92.770012,32.309250 
	C92.717979,31.488792 92.693077,31.322645 92.668167,31.156500 
	C95.345589,26.031034 97.953415,25.240330 104.242760,28.200848 
	C105.122337,29.112856 105.546364,29.609175 105.970398,30.105492 
	C105.970398,30.105492 105.918930,29.969337 105.794968,30.463005 
	C105.695335,51.340389 105.627640,71.724976 105.820526,92.107101 
	C105.859756,96.252304 104.738884,99.459541 101.480003,101.940048 
z"
        />
        <path
          fill="#F9FAFC"
          opacity="1.000000"
          stroke="none"
          d="
M29.391300,101.883659 
	C22.365547,101.409424 21.082304,100.000664 21.079687,92.664322 
	C21.072741,73.177528 20.983629,53.689682 21.201784,34.205334 
	C21.228018,31.862246 22.459270,28.040094 23.989426,27.543051 
	C26.523167,26.720011 29.754391,28.044174 32.982513,29.165041 
	C33.502491,30.972651 33.929474,32.078510 33.931618,33.185188 
	C33.971321,53.671768 33.986546,74.158539 33.913742,94.644936 
	C33.907452,96.415558 33.248589,98.183853 32.892860,99.953232 
	C32.892860,99.953232 32.967247,99.965981 32.678123,99.972992 
	C32.220104,100.311539 32.051212,100.643074 31.882317,100.974609 
	C31.882315,100.974609 31.989809,100.995239 31.628189,100.926468 
	C30.641479,101.199684 30.016390,101.541672 29.391300,101.883659 
z"
        />
        <path
          fill="#FAFBFC"
          opacity="1.000000"
          stroke="none"
          d="
M36.704674,40.029472 
	C40.338905,33.993156 43.990993,33.392532 48.846210,38.300671 
	C53.960217,43.470417 58.882744,48.829575 64.393059,54.637302 
	C69.761467,49.132999 74.480125,44.285469 79.209862,39.448776 
	C82.358803,36.228630 85.697357,35.734730 89.623032,39.359497 
	C89.750114,42.348488 90.409042,45.527313 89.115685,47.081707 
	C83.971390,53.264301 78.395569,59.124012 72.604500,64.714798 
	C70.228783,67.008347 66.901863,68.316635 63.710373,69.924446 
	C61.993237,68.779549 60.573364,67.783302 59.153496,66.787056 
	C59.153496,66.787056 59.020706,66.855972 59.008926,66.636360 
	C58.664825,66.250710 58.332512,66.084679 58.000195,65.918640 
	C58.000195,65.918640 58.085968,65.981888 58.005299,65.662354 
	C57.317005,64.845901 56.709377,64.348984 56.076447,63.898178 
	C56.051144,63.944286 56.144665,63.992443 56.058578,63.714912 
	C55.649323,63.245621 55.326153,63.053860 55.004616,62.929504 
	C55.006256,62.996906 55.141048,62.993347 55.055305,62.716377 
	C54.647163,62.247944 54.324764,62.056477 54.003899,61.931248 
	C54.005440,61.997486 54.137909,61.994175 54.052338,61.717781 
	C53.645096,61.250145 53.323433,61.058899 53.003246,60.932865 
	C53.004726,60.998074 53.135136,60.994919 53.049622,60.719112 
	C52.643135,60.252296 52.322159,60.061287 52.002609,59.934456 
	C52.004032,59.998638 52.132385,59.995636 52.046917,59.720428 
	C51.641174,59.254501 51.320900,59.063782 51.002037,58.936150 
	C51.003445,58.999237 51.129616,58.996292 51.044270,58.721672 
	C50.639317,58.256691 50.319714,58.066334 50.001495,57.937881 
	C50.002884,57.999783 50.126690,57.996922 50.041542,57.722870 
	C49.637470,57.258877 49.318550,57.068943 48.996582,56.938705 
	C48.993538,56.998402 49.112923,57.004642 49.033287,56.727497 
	C48.648201,56.227665 48.342751,56.004982 47.897530,55.488487 
	C44.897423,52.452312 42.037079,49.709949 39.057037,46.711517 
	C38.634731,46.239407 38.332127,46.023361 38.002953,45.611916 
	C37.893066,45.135178 37.707829,44.964390 37.303093,44.506863 
	C37.025238,42.749542 36.864956,41.389507 36.704674,40.029472 
z"
        />
        <path
          fill="#174982"
          opacity="1.000000"
          stroke="none"
          d="
M101.895752,101.956955 
	C104.738884,99.459541 105.859756,96.252304 105.820526,92.107101 
	C105.627640,71.724976 105.695335,51.340389 105.726929,30.488846 
	C106.507774,31.716812 107.850883,33.408089 107.863312,35.109093 
	C108.010002,55.195236 107.925659,75.282928 107.984947,95.369965 
	C107.996696,99.352379 106.422310,101.678162 101.895752,101.956955 
z"
        />
        <path
          fill="#254A76"
          opacity="1.000000"
          stroke="none"
          d="
M33.273155,99.913452 
	C33.248589,98.183853 33.907452,96.415558 33.913742,94.644936 
	C33.986546,74.158539 33.971321,53.671768 33.931618,33.185188 
	C33.929474,32.078510 33.502491,30.972651 33.180012,29.461483 
	C37.756367,31.378559 35.156528,35.725800 35.716366,39.908340 
	C35.486149,42.156380 35.436222,43.638611 35.345642,45.484055 
	C35.509289,47.232483 35.713596,48.617699 35.917900,50.002918 
	C35.904182,64.055206 36.000763,78.109253 35.776852,92.158188 
	C35.735691,94.740646 34.394547,97.302368 33.273155,99.913452 
z"
        />
        <path
          fill="#2569B6"
          opacity="1.000000"
          stroke="none"
          d="
M64.007637,70.073105 
	C66.901863,68.316635 70.228783,67.008347 72.604500,64.714798 
	C78.395569,59.124012 83.971390,53.264301 89.115685,47.081707 
	C90.409042,45.527313 89.750114,42.348488 89.952049,39.539688 
	C90.566719,38.814137 91.217720,38.465096 92.239960,38.056190 
	C92.662819,42.390846 92.714462,46.785358 92.689812,51.582901 
	C90.614655,52.949730 88.258293,53.520523 86.684708,54.951866 
	C82.169014,59.059372 78.232803,63.834034 73.537720,67.701744 
	C71.139557,69.677299 67.604156,70.272331 64.289932,71.036255 
	C64.000610,70.411819 64.004120,70.242462 64.007637,70.073105 
z"
        />
        <path
          fill="#2569B6"
          opacity="1.000000"
          stroke="none"
          d="
M36.261971,50.066467 
	C35.713596,48.617699 35.509289,47.232483 35.679451,45.440521 
	C36.509499,44.990570 36.965084,44.947361 37.420666,44.904148 
	C37.707829,44.964390 37.893066,45.135178 38.160698,45.836960 
	C38.622257,46.494133 38.899498,46.730858 39.176739,46.967583 
	C42.037079,49.709949 44.897423,52.452312 48.044220,55.719669 
	C48.591423,56.497986 48.852173,56.751316 49.112923,57.004642 
	C49.112923,57.004642 48.993538,56.998402 49.159164,57.149284 
	C49.592091,57.532417 49.859390,57.764671 50.126690,57.996922 
	C50.126690,57.996922 50.002884,57.999783 50.164341,58.149017 
	C50.593739,58.530930 50.861675,58.763611 51.129616,58.996292 
	C51.129616,58.996292 51.003445,58.999237 51.165115,59.147804 
	C51.595318,59.529461 51.863853,59.762547 52.132385,59.995636 
	C52.132385,59.995636 52.004032,59.998638 52.165894,60.146622 
	C52.596886,60.528042 52.866009,60.761482 53.135136,60.994919 
	C53.135136,60.994919 53.004726,60.998074 53.166748,61.145489 
	C53.598480,61.526661 53.868195,61.760418 54.137909,61.994175 
	C54.137909,61.994175 54.005440,61.997486 54.167664,62.144333 
	C54.600277,62.525234 54.870663,62.759293 55.141048,62.993347 
	C55.141048,62.993347 55.006256,62.996906 55.168716,63.143101 
	C55.602337,63.523678 55.873501,63.758060 56.144665,63.992443 
	C56.144665,63.992443 56.051144,63.944286 56.045250,64.280579 
	C56.034344,65.130028 56.029335,65.643196 56.024323,66.156357 
	C54.437077,65.352814 52.597275,64.835083 51.307262,63.695171 
	C46.311848,59.280972 41.491550,54.668591 36.261971,50.066467 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M63.710373,69.924446 
	C64.004120,70.242462 64.000610,70.411819 63.993446,70.836349 
	C63.242691,71.068024 62.495586,71.044525 61.288696,70.717972 
	C60.208633,69.956505 59.588348,69.498093 58.968067,69.039673 
	C58.980663,68.536156 58.993259,68.032631 59.079674,67.158081 
	C60.573364,67.783302 61.993237,68.779549 63.710373,69.924446 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M37.303093,44.506863 
	C36.965084,44.947361 36.509499,44.990570 35.720108,45.077316 
	C35.436222,43.638611 35.486149,42.156380 35.707008,40.352173 
	C35.877941,40.030201 36.291325,40.037315 36.498001,40.033394 
	C36.864956,41.389507 37.025238,42.749542 37.303093,44.506863 
z"
        />
        <path
          fill="#2569B6"
          opacity="1.000000"
          stroke="none"
          d="
M59.062149,69.327209 
	C59.588348,69.498093 60.208633,69.956505 60.914932,70.708954 
	C60.386044,70.540230 59.771137,70.077492 59.062149,69.327209 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M56.380081,66.126831 
	C56.029335,65.643196 56.034344,65.130028 56.070557,64.234467 
	C56.709377,64.348984 57.317005,64.845901 58.036835,65.619400 
	C57.677975,65.963097 57.206909,66.030197 56.380081,66.126831 
z"
        />
        <path
          fill="#254A76"
          opacity="1.000000"
          stroke="none"
          d="
M29.761189,101.854691 
	C30.016390,101.541672 30.641479,101.199684 31.638304,100.872696 
	C31.383717,101.200363 30.757397,101.513046 29.761189,101.854691 
z"
        />
        <path
          fill="#174982"
          opacity="1.000000"
          stroke="none"
          d="
M105.857475,29.789928 
	C105.546364,29.609175 105.122337,29.112856 104.555328,28.299709 
	C104.856415,28.480042 105.300484,28.977203 105.857475,29.789928 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M58.150791,66.126587 
	C58.332512,66.084679 58.664825,66.250710 59.042923,66.702988 
	C58.826267,66.771004 58.563828,66.552773 58.150791,66.126587 
z"
        />
        <path
          fill="#2569B6"
          opacity="1.000000"
          stroke="none"
          d="
M92.522621,31.244326 
	C92.693077,31.322645 92.717979,31.488792 92.678947,31.898472 
	C92.478745,31.968210 92.323792,31.804680 92.218948,31.613651 
	C92.191559,31.563776 92.320129,31.428308 92.522621,31.244326 
z"
        />
        <path
          fill="#2569B6"
          opacity="1.000000"
          stroke="none"
          d="
M91.500313,96.507172 
	C91.480934,96.515205 91.519691,96.499138 91.500313,96.507172 
z"
        />
        <path
          fill="#254A76"
          opacity="1.000000"
          stroke="none"
          d="
M32.136452,100.875992 
	C32.051212,100.643074 32.220104,100.311539 32.683517,99.935638 
	C32.782223,100.186653 32.586407,100.482018 32.136452,100.875992 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M50.041542,57.722870 
	C49.859390,57.764671 49.592091,57.532417 49.162209,57.089584 
	C49.318550,57.068943 49.637470,57.258877 50.041542,57.722870 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M51.044270,58.721672 
	C50.861675,58.763611 50.593739,58.530930 50.162956,58.087112 
	C50.319714,58.066334 50.639317,58.256691 51.044270,58.721672 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M52.046917,59.720428 
	C51.863853,59.762547 51.595318,59.529461 51.163704,59.084717 
	C51.320900,59.063782 51.641174,59.254501 52.046917,59.720428 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M53.049622,60.719112 
	C52.866009,60.761482 52.596886,60.528042 52.164474,60.082443 
	C52.322159,60.061287 52.643135,60.252296 53.049622,60.719112 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M54.052338,61.717781 
	C53.868195,61.760418 53.598480,61.526661 53.165268,61.080280 
	C53.323433,61.058899 53.645096,61.250145 54.052338,61.717781 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M55.055305,62.716377 
	C54.870663,62.759293 54.600277,62.525234 54.166126,62.078094 
	C54.324764,62.056477 54.647163,62.247944 55.055305,62.716377 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M56.058578,63.714912 
	C55.873501,63.758060 55.602337,63.523678 55.167076,63.075699 
	C55.326153,63.053860 55.649323,63.245621 56.058578,63.714912 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M49.033287,56.727497 
	C48.852173,56.751316 48.591423,56.497986 48.183987,56.013477 
	C48.342751,56.004982 48.648201,56.227665 49.033287,56.727497 
z"
        />
        <path
          fill="#2A61A0"
          opacity="1.000000"
          stroke="none"
          d="
M39.057037,46.711517 
	C38.899498,46.730858 38.622257,46.494133 38.187271,46.032364 
	C38.332127,46.023361 38.634731,46.239407 39.057037,46.711517 
z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
