import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// follow
const FollowVietPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/follow/follow-viet')
);
const FollowNhanhPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/follow/follow-nhanh')
);
const FollowNgoaiPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/follow/follow-ngoai')
);

// like
const LikePostVietPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/like/like-viet')
);
const LikePostNgoaiPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/like/like-ngoai')
);

// like favorite
const LikeFavoritePage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/like-favorite')
);

const ViewVideoPage = lazy(() => import('src/pages/dashboard/services/tiktok-services/view-video'));

const ShareVideoPage = lazy(() => import('src/pages/dashboard/services/tiktok-services/share'));

const CommentPage = lazy(() => import('src/pages/dashboard/services/tiktok-services/comment'));

// live stream
const LikeLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/livestream/like-livestream')
);
const ShareLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/livestream/share-livestream')
);
const PKLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/livestream/pk-livestream')
);
const ViewLiveStreamPage = lazy(() =>
  import('src/pages/dashboard/services/tiktok-services/livestream/view-livestream')
);

export const tiktokRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.tiktok.root,
        element: <Navigate to={paths.tiktok.followViet} replace />,
      },

      // tiktok - follow
      {
        path: paths.tiktok.follow,
        element: <Navigate to={paths.tiktok.followViet} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.tiktok.followViet, element: <FollowVietPage /> },
          { path: paths.tiktok.followNgoai, element: <FollowNgoaiPage /> },
          { path: paths.tiktok.followNhanh, element: <FollowNhanhPage /> },
        ],
      },

      // tiktok - like
      {
        path: paths.tiktok.likePost,
        element: <Navigate to={paths.tiktok.likePostViet} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.tiktok.likePostViet, element: <LikePostVietPage /> },
          { path: paths.tiktok.likePostNgoai, element: <LikePostNgoaiPage /> },
        ],
      },

      // like favorite
      { path: paths.tiktok.likeFavorite, element: <LikeFavoritePage /> },

      // view
      { path: paths.tiktok.view, element: <ViewVideoPage /> },

      // share
      { path: paths.tiktok.share, element: <ShareVideoPage /> },

      // Comment
      { path: paths.tiktok.comment, element: <CommentPage /> },

      // tiktok - live stream
      {
        path: paths.tiktok.livestream,
        element: <Navigate to={paths.tiktok.likeLivestream} replace />,
      },
      {
        path: '',
        element: <Outlet />,
        children: [
          { path: paths.tiktok.likeLivestream, element: <LikeLiveStreamPage /> },
          { path: paths.tiktok.shareLivestream, element: <ShareLiveStreamPage /> },
          { path: paths.tiktok.pkLivestream, element: <PKLiveStreamPage /> },
          { path: paths.tiktok.viewLivestream, element: <ViewLiveStreamPage /> },
        ],
      },
    ],
  },
];
