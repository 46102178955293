import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const MemberVietTelegramPage = lazy(() =>
  import('src/pages/dashboard/cms/services/telegram-services/member-viet')
);
const MemberNgoaiTelegramPage = lazy(() =>
  import('src/pages/dashboard/cms/services/telegram-services/member-ngoai')
);

// ----------------------------------------------------------------------

export const cmsTelegramRoutes = [
  {
    path: paths.cms.service.telegram.root,
    element: <Navigate to={paths.cms.service.telegram.memberViet} replace />,
  },

  {
    path: paths.cms.service.telegram.member,
    element: <Navigate to={paths.cms.service.telegram.memberViet} replace />,
  },

  { path: paths.cms.service.telegram.memberViet, element: <MemberVietTelegramPage /> },

  { path: paths.cms.service.telegram.memberNgoai, element: <MemberNgoaiTelegramPage /> },
];
