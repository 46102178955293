import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const FollowLazadaPage = lazy(() =>
  import('src/pages/dashboard/cms/services/lazada-services/follow')
);
const LikeLazadaPage = lazy(() => import('src/pages/dashboard/cms/services/lazada-services/like'));

// ----------------------------------------------------------------------

export const cmsLazadaRoutes = [
  {
    path: paths.cms.service.lazada.root,
    element: <Navigate to={paths.cms.service.lazada.follow} replace />,
  },

  { path: paths.cms.service.lazada.follow, element: <FollowLazadaPage /> },

  { path: paths.cms.service.lazada.like, element: <LikeLazadaPage /> },
];
