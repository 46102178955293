import PropTypes from 'prop-types';
// components
import { useRouter } from 'src/routes/hooks';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  // Logic here to get current user role
  const { user } = useAuthContext();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const currentRole = user?.role;

  useEffect(() => {
    if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
      router.replace('/403');
    } else {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <> {checked && children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
