import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { ROLE } from 'src/utils/constants';
import { paths } from '../paths';
import { cmsFacebookServiceRoutes } from './cms-services/cms-facebook-services';
import { cmsYoutubeRoutes } from './cms-services/cms-youtube-services';
import { cmsTikTokRoutes } from './cms-services/cms-tiktok-services';
import { cmsInstagramRoutes } from './cms-services/cms-instagram-services';
import { cmsTwitterRoutes } from './cms-services/cms-twitter-services';
import { cmsTelegramRoutes } from './cms-services/cms-telegram-services';
import { cmsShopeeRoutes } from './cms-services/cms-shopee-services';
import { cmsLazadaRoutes } from './cms-services/cms-lazada-services';
import { cmsGoogleRoutes } from './cms-services/cms-google-services';

// ----------------------------------------------------------------------

// Proxy CMS
const ProxyCountryCMSPage = lazy(() => import('src/pages/dashboard/cms/proxy-country'));
const ProxyTypeCMSPage = lazy(() => import('src/pages/dashboard/cms/proxy-type'));
const ProxyCMSPage = lazy(() => import('src/pages/dashboard/cms/proxy'));
const ProxyResidentialPage = lazy(() => import('src/pages/dashboard/cms/proxy-residential'));
const ProxyTokenPage = lazy(() => import('src/pages/dashboard/cms/proxy-token'));
const ProxyRegionCMSPage = lazy(() => import('src/pages/dashboard/cms/proxy-region'));
const ProductCMSPage = lazy(() => import('src/pages/dashboard/cms/product'));
const ProxyVPSCMSPage = lazy(() => import('src/pages/dashboard/cms/proxy-vps/proxy-vps-list'));
const ProxyVPSStatisticsCMSPage = lazy(() =>
  import('src/pages/dashboard/cms/proxy-vps/statistics')
);

// VPS CMS
const VPSOrderCMSPage = lazy(() => import('src/pages/dashboard/cms/vps/vps-order'));
const VPSTypeCMSPage = lazy(() => import('src/pages/dashboard/cms/vps/vps-type'));
const VPSListCMSPage = lazy(() => import('src/pages/dashboard/cms/vps/vps-list'));

// User CMS
const UsersCMSPage = lazy(() => import('src/pages/dashboard/cms/users/users'));
const RechargeHistoryPage = lazy(() =>
  import('src/pages/dashboard/cms/transaction-history/recharge')
);
const BankingHistoryPage = lazy(() =>
  import('src/pages/dashboard/cms/transaction-history/banking')
);
const OrdersCMSPage = lazy(() => import('src/pages/dashboard/cms/order/order'));

// transaction-history
const ProxyOrdersCMSPage = lazy(() => import('src/pages/dashboard/cms/order/proxy-order'));

// services
const ServiceTypesCMSPage = lazy(() =>
  import('src/pages/dashboard/cms/services/service-type/service-type')
);

// statictics
const StatisticsPage = lazy(() => import('src/pages/dashboard/cms/statistics'));

const CronjobPage = lazy(() => import('src/pages/dashboard/cms/cronjob'));

const ProfitPage = lazy(() => import('src/pages/dashboard/cms/profit'));

// ----------------------------------------------------------------------

export const cmsRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <RoleBasedGuard roles={[ROLE.ADMIN, ROLE.SUPER_ADMIN]}>
            <Outlet />
          </RoleBasedGuard>
        ),
        children: [
          { path: paths.cms.proxy, element: <ProxyCMSPage /> },
          { path: paths.cms.proxyResidential, element: <ProxyResidentialPage /> },
          { path: paths.cms.proxyToken, element: <ProxyTokenPage /> },
          { path: paths.cms.proxyCountry, element: <ProxyCountryCMSPage /> },
          { path: paths.cms.proxyRegion, element: <ProxyRegionCMSPage /> },
          { path: paths.cms.proxyType, element: <ProxyTypeCMSPage /> },
          { path: paths.cms.product, element: <ProductCMSPage /> },
          { path: paths.cms.proxyVPS, element: <ProxyVPSCMSPage /> },
          { path: paths.cms.proxyVPSStatistics, element: <ProxyVPSStatisticsCMSPage /> },

          { path: paths.cms.statistics, element: <StatisticsPage /> },

          { path: paths.cms.cronjob, element: <CronjobPage /> },

          { path: paths.cms.vps.vpsType, element: <VPSTypeCMSPage /> },

          { path: paths.cms.vps.vpsList, element: <VPSListCMSPage /> },

          { path: paths.cms.profit, element: <ProfitPage /> },

          { path: paths.cms.user, element: <UsersCMSPage /> },

          { path: paths.cms.transactionHistory.recharge, element: <RechargeHistoryPage /> },

          { path: paths.cms.transactionHistory.banking, element: <BankingHistoryPage /> },

          { path: paths.cms.order.orders, element: <OrdersCMSPage /> },
          { path: paths.cms.order.vps, element: <VPSOrderCMSPage /> },
          { path: paths.cms.order.proxyOrders, element: <ProxyOrdersCMSPage /> },

          { path: paths.cms.service.services, element: <ServiceTypesCMSPage /> },
          ...cmsFacebookServiceRoutes,
          ...cmsYoutubeRoutes,
          ...cmsTikTokRoutes,
          ...cmsInstagramRoutes,
          ...cmsTwitterRoutes,
          ...cmsTelegramRoutes,
          ...cmsShopeeRoutes,
          ...cmsLazadaRoutes,
          ...cmsGoogleRoutes,
        ],
      },
    ],
  },
];
