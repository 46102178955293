import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

const ReviewMapGooglePage = lazy(() =>
  import('src/pages/dashboard/services/google-services/review-google-map')
);

export const googleRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.google.root, element: <Navigate to={paths.google.reviewMap} replace /> },
      { path: paths.google.reviewMap, element: <ReviewMapGooglePage /> },
    ],
  },
];
