import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const FollowShopeePage = lazy(() =>
  import('src/pages/dashboard/cms/services/shopee-services/follow')
);
const LikeShopeePage = lazy(() => import('src/pages/dashboard/cms/services/shopee-services/like'));

// ----------------------------------------------------------------------

export const cmsShopeeRoutes = [
  {
    path: paths.cms.service.shopee.root,
    element: <Navigate to={paths.cms.service.shopee.follow} replace />,
  },

  { path: paths.cms.service.shopee.follow, element: <FollowShopeePage /> },

  { path: paths.cms.service.shopee.like, element: <LikeShopeePage /> },
];
