import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

// subscribe
const SubscribeVietPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/subscribe/subscribe-viet')
);
const SubscribeNgoaiPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/subscribe/subscribe-ngoai')
);

// like video
const LikeVideoPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/like-video')
);

// dislike video
const DislikeVideoPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/dislike-video')
);

// view
const ViewNormalPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/view/view-normal')
);
const ViewSocialPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/view/view-social')
);
const ViewNativeADS30Page = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/view/view-native-ADS-30')
);
const ViewNativeADS500Page = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/view/view-native-ADS-500')
);

// watch time
const WatchTimePage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/watch-time')
);

// comment
const LikeCommentPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/comment/like-comment')
);
const CommentVietPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/comment/comment-viet')
);
const CommentNgoaiPage = lazy(() =>
  import('src/pages/dashboard/cms/services/youtube-services/comment/comment-ngoai')
);

// ----------------------------------------------------------------------

export const cmsYoutubeRoutes = [
  {
    path: paths.cms.service.youtube.root,
    element: <Navigate to={paths.cms.service.youtube.subscribeViet} replace />,
  },

  // youtube - like post
  {
    path: paths.cms.service.youtube.subscribe,
    element: <Navigate to={paths.cms.service.youtube.subscribeViet} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.youtube.subscribeViet, element: <SubscribeVietPage /> },
      { path: paths.cms.service.youtube.subscribeNgoai, element: <SubscribeNgoaiPage /> },
    ],
  },

  // like video
  { path: paths.cms.service.youtube.likeVideo, element: <LikeVideoPage /> },

  // dislike video
  { path: paths.cms.service.youtube.dislikeVideo, element: <DislikeVideoPage /> },

  // watch time
  { path: paths.cms.service.youtube.watchTime, element: <WatchTimePage /> },

  // like comment
  { path: paths.cms.service.youtube.likeComment, element: <LikeCommentPage /> },

  // view
  {
    path: paths.cms.service.youtube.view,
    element: <Navigate to={paths.cms.service.youtube.viewNormal} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.youtube.viewNormal, element: <ViewNormalPage /> },
      { path: paths.cms.service.youtube.viewSocial, element: <ViewSocialPage /> },
      { path: paths.cms.service.youtube.viewNativeADS30, element: <ViewNativeADS30Page /> },
      { path: paths.cms.service.youtube.viewNativeADS500, element: <ViewNativeADS500Page /> },
    ],
  },

  // comment
  {
    path: paths.cms.service.youtube.comment,
    element: <Navigate to={paths.cms.service.youtube.commentViet} replace />,
  },
  {
    path: '',
    element: <Outlet />,
    children: [
      { path: paths.cms.service.youtube.commentViet, element: <CommentVietPage /> },
      { path: paths.cms.service.youtube.commentNgoai, element: <CommentNgoaiPage /> },
    ],
  },
];
