import PropTypes from 'prop-types';
// mui
import { Alert, Stack } from '@mui/material';
// components
import { ConfirmDialog } from 'src/components/custom-dialog';
import Image from 'src/components/image';

const RechargeBankingDialog = ({ title, open, onClose, qr }) => (
  <ConfirmDialog
    maxWidth="sm"
    open={open}
    onClose={onClose}
    closeButtonName="Đóng"
    title={title}
    content={
      <Stack spacing={3}>
        <Stack spacing={0.5}>
          <Alert severity="info">Quét mã QR hoặc chuyển khoản theo cú pháp</Alert>
        </Stack>
        <Stack direction="row">
          <Image
            alt="QRcode"
            src={qr}
            ratio="1/1"
            sx={{
              borderRadius: 1,
              cursor: 'pointer',
              width: '400px',
              height: '400px',
              mx: 'auto',
            }}
          />
        </Stack>
      </Stack>
    }
  />
);

export default RechargeBankingDialog;

RechargeBankingDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  qr: PropTypes.string,
  title: PropTypes.string,
};
