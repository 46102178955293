import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import DashboardLayout from 'src/layouts/dashboard/layout';
import { paths } from '../../paths';

// ----------------------------------------------------------------------

const FollowTwitterPage = lazy(() =>
  import('src/pages/dashboard/services/twitter-services/follow')
);

const LikeTwitterPage = lazy(() => import('src/pages/dashboard/services/twitter-services/like'));

export const twitterRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.twitter.root, element: <Navigate to={paths.twitter.follow} replace /> },
      { path: paths.twitter.follow, element: <FollowTwitterPage /> },
      { path: paths.twitter.like, element: <LikeTwitterPage /> },
    ],
  },
];
